import { Box, Grid } from '@mui/material';
import { useEffect, useState } from 'react';
import Pagination from 'react-js-pagination';
import 'react-loader-spinner/dist/loader/css/react-spinner-loader.css';
import { useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom/cjs/react-router-dom.min';
import 'reactjs-popup/dist/index.css';
import backIcon from '../../../assets/img/icons/atras-icon.svg';
import excelIcon from '../../../assets/img/icons/excelIcon.svg';
import Search from '../../../assets/img/icons/lupa.svg';
import paginationStyles from '../../Layouts/pagination.module.scss';
import tableStyles from '../../Layouts/tableStyle.module.scss';
import '../../TableUsers/tableUserStyle.scss';

import { OverlayTrigger } from 'react-bootstrap';
import { useGetMethod } from '../../../Hooks/useFetch';
import { loader } from '../../../helpers/helpers';
import { renderToolImportance } from '../../../helpers/renderToolImportance';
import { GenericTableScrollDynamic } from '../../Layouts';
import { customSwaltAlert } from './../../../helpers/customSwaltAlert';
import { convertMoneyFormat, downloadExcel } from './../../../helpers/helpers';

export const Provisions = () => {
  const location = useLocation();
  const store = useSelector(state => state);
  const token = store.loginReducer.Authorization;

  const history = useHistory();

  const [data, setData] = useState({
    trigger: 0,
    emptyProvisions: false,
    filters: {
      search: '',
      page: 1,
      perpage: 10,
      idPayroll: location?.state?.IdPayroll,
    },
  });

  const { filters, trigger, emptyProvisions } = data;

  const {
    results: listProvisions,
    trigger: getListProvisions,
    load: loadListProvisions,
  } = useGetMethod();

  const { trigger: getListDownloadExcel, load: loadListDownloadExcel } = useGetMethod();

  const headerStatic = [
    <th key={1} className='text-center px-2'>
      ID
    </th>,

    <th key={2} className='text-start px-2'>
      Empleado
    </th>,

    <th key={3} className='text-end px-2'>
      Base 1
    </th>,

    <th key={4} className='text-end px-2'>
      Base 2
    </th>,

    <th key={5} className='text-end px-2'>
      Base 3
    </th>,
  ];

  const bodyStatic = () => {
    return listProvisions?.results?.map(elem => (
      <tr key={elem?.candidateDocument}>
        <td className={`text-center px-2`}>
          {elem?.candidateDocument ? elem?.candidateDocument : '-'}
        </td>

        <td className='text-start px-2'>{elem?.candidateName ? elem?.candidateName : '-'}</td>

        <td className='text-end px-2'>
          {elem.baseSalary1 ? convertMoneyFormat(Number(elem?.baseSalary1).toFixed(3)) : '-'}
        </td>

        <td className='text-end px-2'>
          {elem.baseSalary2 ? convertMoneyFormat(Number(elem?.baseSalary2).toFixed(3)) : '-'}
        </td>

        <td className='text-end px-2'>
          {elem.baseSalary3 ? convertMoneyFormat(Number(elem?.baseSalary3).toFixed(3)) : '-'}
        </td>
      </tr>
    ));
  };

  const bodyDynamic = () => {
    return listProvisions?.results?.map(elem => (
      <tr key={elem?.id}>
        <td style={{ minWidth: '100px' }} className='text-end px-2'>
          {elem?.health ? convertMoneyFormat(Number(elem?.health).toFixed(3)) : '-'}
        </td>
        <td className={`text-end px-2`}>
          {elem?.pension ? convertMoneyFormat(Number(elem?.pension).toFixed(3)) : '-'}
        </td>
        <td className={`text-end px-2`}>
          {elem?.arl ? convertMoneyFormat(Number(elem?.arl).toFixed(3)) : '-'}
        </td>
        <td className={`text-end px-2`}>
          {elem?.compensationFund
            ? convertMoneyFormat(Number(elem?.compensationFund).toFixed(3))
            : '-'}
        </td>
        <td className={`text-end px-2`}>
          {elem?.icbf ? convertMoneyFormat(Number(elem?.icbf).toFixed(3)) : '-'}
        </td>
        <td className={`text-end px-2`}>
          {elem?.sena ? convertMoneyFormat(Number(elem?.sena).toFixed(3)) : '-'}
        </td>
        <td className={`text-end px-2`}>
          {elem?.severancePay ? convertMoneyFormat(Number(elem?.severancePay).toFixed(3)) : '-'}
        </td>
        <td style={{ minWidth: '100px' }} className='text-end px-2'>
          {elem?.severanceInterest
            ? convertMoneyFormat(Number(elem?.severanceInterest).toFixed(3))
            : '-'}
        </td>
        <td className={`text-end px-2`}>
          {elem?.bonusPay ? convertMoneyFormat(Number(elem?.bonusPay).toFixed(3)) : '-'}
        </td>
        <td className={`text-end px-2`}>
          {elem?.vacations ? convertMoneyFormat(Number(elem?.vacations).toFixed(3)) : '-'}
        </td>
      </tr>
    ));
  };

  const headerDynamic = [
    <th key={1} style={{ minWidth: '100px' }} className='text-end px-2'>
      AP Salud
    </th>,

    <th key={2} className='text-end px-2'>
      Pensión
    </th>,

    <th key={3} className='text-end px-2'>
      ARL
    </th>,

    <th key={4} className='text-end px-2'>
      Caja
    </th>,

    <th key={5} className='text-end px-2'>
      ICBF
    </th>,

    <th key={6} className='text-end px-2'>
      Sena
    </th>,

    <th key={7} className='text-end px-2'>
      Cesantías
    </th>,

    <th key={8} style={{ minWidth: '100px' }} className='text-end px-2'>
      Int. cesantía
    </th>,

    <th key={9} className='text-end px-2'>
      Primas
    </th>,

    <th key={10} className='text-end px-2'>
      Vacaciones
    </th>,

    // <th key={9} className="text-start col-1">
    //     &nbsp;
    // </th>,
  ];

  useEffect(() => {
    if (trigger) {
      getListProvisions({
        url: `/payroll/provisions-detail`,
        token: token,
        objFilters: data?.filters,
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [trigger]);

  useEffect(() => {
    getListProvisions({
      url: `/payroll/provisions-detail`,
      token: token,
      objFilters: data?.filters,
    }).then(e => {
      if (e?.results && !e?.results?.length) {
        setData(state => ({ ...state, emptyProvisions: true }));
        customSwaltAlert({
          icon: 'info',
          title: `Información`,
          text: `No han sido generadas las provisiones de esta planilla`,
          confirmButtonText: `Aceptar`,
          showCancelButton: false,
        }).then(() => {
          history.goBack();
        });
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {(loadListProvisions || loadListDownloadExcel) && loader}

      <div className='w-90 mx-auto'>
        <div className='d-flex'>
          <img
            src={backIcon}
            alt='volver'
            width={24}
            className={`${tableStyles.title} ${tableStyles.marginBackIcon} hoverPointer`}
            onClick={() => history.goBack()}
          />

          <h1 className={`${tableStyles.title}`}>Provisiones de nómina</h1>
        </div>

        {!emptyProvisions ? (
          <>
            <Box>
              <Box display={'grid'} gridTemplateColumns={'470px 1fr'}>
                <div className='d-flex gap-1'>
                  <input
                    className={`${tableStyles.SearchNew} mr-2`}
                    style={{ flex: 1, color: `#73a6dc`, height: '27px' }}
                    name='search'
                    type='text'
                    placeholder='Buscar...'
                    value={filters.search}
                    onChange={e =>
                      setData(state => ({
                        ...state,
                        filters: { ...state.filters, search: e.target.value },
                      }))
                    }
                    onKeyDown={e => {
                      if (e.key === 'Enter') {
                        setData(state => ({ ...state, trigger: state.trigger + 1 }));
                      }
                    }}
                  />

                  <img
                    src={Search}
                    style={{ cursor: 'pointer' }}
                    alt='Search icon'
                    height='27px'
                    onClick={() => {
                      setData(state => ({ ...state, trigger: state.trigger + 1 }));
                    }}
                  />
                </div>

                <div className={`d-flex gap-1 justify-content-end`}>
                  <OverlayTrigger
                    placement='top'
                    delay={{ show: 250, hide: 400 }}
                    overlay={renderToolImportance('Descargar')}
                  >
                    <img
                      src={excelIcon}
                      alt='planilla'
                      className='pointer'
                      height='27px'
                      onClick={() => {
                        if (listProvisions?.results?.length) {
                          getListDownloadExcel({
                            url: `/payroll/provision_report`,
                            token: token,
                            objFilters: { excel: 1, idPayroll: data?.filters?.idPayroll },
                          }).then(xls => {
                            downloadExcel(xls?.results?.base64, xls?.results?.filename, true);
                          });
                        }
                      }}
                    />
                  </OverlayTrigger>
                </div>
              </Box>
            </Box>

            <Grid container>
              <Grid item xs={12}>
                <GenericTableScrollDynamic
                  dark={true}
                  columns={'55% 45%'}
                  headerStatic={headerStatic}
                  bodyStatic={() => bodyStatic()}
                  headerDynamic={headerDynamic}
                  bodyDynamic={() => bodyDynamic()}
                />
              </Grid>

              <Grid item xs={12} mt={2}>
                <div className={paginationStyles.wrapper}>
                  <p className={`${paginationStyles.paginationText} text-secondary`}>
                    Pag. {listProvisions?.results?.length ? filters.page : ''}
                    {' de '}
                    {Math.ceil(listProvisions?.rowTotal / filters.perpage)
                      ? Math.ceil(listProvisions?.rowTotal / filters.perpage)
                      : ''}{' '}
                    ({listProvisions?.rowTotal} encontrados)
                  </p>
                  <Pagination
                    activePage={filters.page}
                    itemsCountPerPage={filters.perpage}
                    totalItemsCount={listProvisions?.rowTotal}
                    pageRangeDisplayed={5}
                    onChange={e =>
                      setData(state => ({
                        ...state,
                        trigger: state.trigger + 1,
                        filters: { ...filters, page: e },
                      }))
                    }
                    itemClassPrev={paginationStyles.itemClassPrev}
                    itemClassNext={paginationStyles.itemClassNext}
                    itemClassFirst={paginationStyles.itemClassFirst}
                    itemClassLast={paginationStyles.itemClassLast}
                    itemClass={paginationStyles.itemClass}
                  />
                </div>
              </Grid>
            </Grid>
          </>
        ) : (
          ''
        )}
      </div>
    </>
  );
};
