import { useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom/cjs/react-router-dom.min';
import backIcon from '../../assets/img/icons/atras-icon.svg';
import Excel from '../../assets/img/icons/excel.svg';
import SearchIcon from '../../assets/img/icons/lupa.svg';
import tableStyles from '../../components/Layouts/tableStyle.module.scss';
import CustomPopupStyles from '../../components/Popup/customPopup.module.scss';
import IndividualStyles from '../../components/TableIndividualAuction/IndividualAuction_CCS.module.scss';
import { customSwaltAlert, genericDownloadExcel, isEmptyOrUndefined, loader, numberWithCommas } from '../../helpers';
import { useGetMethod, usePostMethod } from '../../Hooks';
import GenericTableScroll from '../Layouts/GenericTableScroll';
import CustomPopupExtend from '../Popup/customPopUpExtends';
import ModalNew from './../Layouts/ModalNew';
import { SupportVisualizer } from './../AreaManagement/SupportVisualizer';

const AccountingReceiptDetail = () => {
  const location = useLocation();
  const history = useHistory();
  const { journal, from } = location.state;
  const storage = useSelector(state => state);
  const eaccount = storage.loginReducer.currentAccount.id;
  const token = storage.loginReducer.Authorization;
  const [showPdf, setShowPdf] = useState(false);

  const [filters, setFilters] = useState({ search: '' });
  const [trigger, setTrigger] = useState(0);
  const { trigger: postDocExcel, load: docExcelLoader } = usePostMethod();

  const {
    load: provisionListLoader,
    results: resList,
    trigger: provisionGetMethod,
  } = useGetMethod();

  const provisionList = resList.results;
  useEffect(() => {
    provisionGetMethod({
      url: '/accounting/journalVoucher/',
      objFilters: { ...filters, eaccount, id: journal },
      token: token,
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [eaccount, filters.page, trigger]);

  const downloadExcelDoc = (id, type) => {
    const body =
      provisionList?.jrnlTypeName === 'Planilla de nómina'
        ? { eaccount: storage.loginReducer.currentAccount.id, id, type }
        : provisionList?.jrnlTypeName === 'Provisión de nómina'
          ? { eaccount: storage.loginReducer.currentAccount.id, id }
          : { eaccount: storage.loginReducer.currentAccount.id, jv_id: id };

    const url =
      provisionList?.jrnlTypeName === 'Planilla de nómina'
        ? '/payroll/download_payroll_accounting/'
        : provisionList?.jrnlTypeName === 'Provisión de nómina'
          ? '/payroll/download_provision_accounting/'
          : '/payroll/download_payment_accounting';
    postDocExcel({
      url: url,
      method: 'POST',
      body: body,
      token: storage.loginReducer.Authorization,
      succesAction: results => {
        genericDownloadExcel(results?.results?.base64, results?.results?.filename);
        customSwaltAlert({
          showCancelButton: false,
          icon: 'success',
          title: 'Excel descargado',
          text: results.message,
          confirmButtonText: 'Aceptar',
        });
      },
      doAfterException: results => {
        customSwaltAlert({
          showCancelButton: false,
          icon: 'warning',
          title: 'Intenta de nuevo',
          text: results.message,
          confirmButtonText: 'Aceptar',
        });
      },
    });
  };

  const header = [
    <div key={1} className={`col-2`}>
      No. Cuenta
    </div>,
    <div key={2} className={`col-3 text-start`}>
      Tercero
    </div>,
    <div key={3} className={`col-3`}>
      Centro de costo
    </div>,
    <div key={4} className={`col-2 text-end`}>
      Debe
    </div>,
    <div key={5} className={`col-2 text-end px-2`}>
      Haber
    </div>,
  ];

  const renderList = () => {
    let tempList = [];
    if (Array.isArray(provisionList?.lines)) {
      provisionList?.lines.forEach((item, index) => {
        tempList.push(
          <section2 className={`d-flex`} key={index}>
            <div className='text-center col-2'>{item.niifAccountNbr}</div>
            <div className='text-start col-3'>{item.thirdName ? item.thirdName : '-'}</div>
            <div className='text-center col-3'>
              {item.costCenterName ? item.costCenterName : '-'}
            </div>
            <div className='text-end col-2'>
              {item.debit ? '$' + numberWithCommas(Number(item.debit)) : '$0'}
            </div>
            <div className='text-end col-2 px-2'>
              {item.credit ? '$' + numberWithCommas(Number(item.credit)) : '$0'}
            </div>
          </section2>,
        );
      });
    }
    return tempList;
  };

  const onOpenSopportAccounting = () => {
    if (isEmptyOrUndefined(provisionList?.journal_file)) {
      return customSwaltAlert({
        icon: 'warning',
        title: 'Intenta de nuevo',
        text: 'No se encontró un archivo para visualizar',
        confirmButtonText: 'Aceptar',
        showCancelButton: false,
      });
    }
    setShowPdf(true)
  }

  return (
    <div className={` ml-5 ${tableStyles.container}`} style={{ marginRight: '3rem' }}>
      {(docExcelLoader || provisionListLoader) && loader}
      <div className='d-flex'>
        <img
          src={backIcon}
          alt='volver'
          width={24}
          className={`${tableStyles.title} ${tableStyles.marginBackIcon} hoverPointer`}
          onClick={() => history.goBack()}
        />
        <h1 className={tableStyles.title}>
          Detalle de comprobante contable {from === 'payroll' ? 'nómina' : 'provisiones'}
        </h1>
      </div>{' '}
      <Row className='d-flex '>
        <Col xs={3}>
          <p className={`${tableStyles.crudModalLabel} `}>Tipo de comprobante</p>
          <input
            className={IndividualStyles.registerInputsClearBlue}
            name='position'
            type='text'
            style={{
              color: '#58595B',
              whiteSspace: 'nowrap',
              textOverflow: 'ellipsis',
              overflow: 'hidden',
            }}
            value={provisionList?.jrnlTypeName}
            disabled
          />
        </Col>
        <Col xs={3}>
          <p className={`${tableStyles.crudModalLabel} `}>Fecha</p>
          <input
            disabled
            className={IndividualStyles.registerInputsClearBlue}
            type='text'
            name='position'
            value={
              provisionList?.date
                ? provisionList?.date.replace(/^(\d{4})-(\d{2})-(\d{2})$/g, '$3/$2/$1')
                : ''
            }
          />
        </Col>
        <Col xs={5}>
          <p className={`${tableStyles.crudModalLabel} `}>Concepto</p>
          <input
            disabled
            className={IndividualStyles.registerInputsClearBlue}
            type='text'
            name='position'
            style={{ color: '#58595B' }}
            value={provisionList?.jvDetail}
          />
        </Col>
        <Col xs={1} className='d-flex align-items-end mt-2'>
          {provisionList?.jrnlTypeName === 'Planilla de nómina' && (
            <CustomPopupExtend
              noHover
              position='bottom'
              triggerSrc={Excel}
              extraButtons={[
                {
                  text: 'Informe general',
                  class: CustomPopupStyles.popUpGeneric,
                  event: () => {
                    downloadExcelDoc(provisionList.jrnlId, 'general');
                  },
                },
                {
                  text: 'Informe detallado',
                  class: CustomPopupStyles.popUpGeneric,
                  event: () => {
                    downloadExcelDoc(provisionList.jrnlId, 'detail');
                  },
                },
              ]}
            />
          )}

          {(provisionList?.jrnlTypeName === 'Pago de nómina' ||
            provisionList?.jrnlTypeName === 'Provisión de nómina') && (
              <img
                alt='Excel'
                className='pointer'
                width={20}
                src={Excel}
                onClick={() => downloadExcelDoc(provisionList.jrnlId)}
              />
            )}
        </Col>
      </Row>
      <Row className='d-flex mt-4'>
        <Col xs={8} className='d-flex gap-2'>
          <input
            type='text'
            placeholder='Buscar terceros o centro de costo...'
            name='search'
            className={`${tableStyles.SearchNew} w-100 me-2`}
            value={filters.search}
            onChange={e => {
              setFilters({
                ...filters,
                search: e.target.value,
              });
            }}
            onKeyDown={e => {
              if (e.key === 'Enter') {
                setTrigger(trigger + 1);
              }
            }}
          />
          <img
            style={{ width: '1.5rem' }}
            src={SearchIcon}
            alt='User icon'
            className={`${tableStyles.iconSvgMargin2} pointer`}
            onClick={async () => {
              setTrigger(trigger + 1);
            }}
          />
        </Col>
      </Row>

      <ModalNew
        title='Comprobante diario'
        show={showPdf}
        btnNoName={'Cancelar'}
        size='700'
        btnYesDisabled={false}
        onHide={() => setShowPdf(false)}
        btnNoEvent={() => setShowPdf(false)}
        btnNoDisabled={false}
      >
        <SupportVisualizer file={provisionList?.journal_file} />
      </ModalNew>

      <GenericTableScroll
        headers={header}
        dark
        body={renderList()}
        typeHead={'2'}
        footer2={
          <div className='d-flex justify-content-end align-items-end w-100'>
            <button className={`${tableStyles.btnSecondary}`} onClick={() => onOpenSopportAccounting()}>
             Visualizar PDF
            </button>
          </div>
        }
      ></GenericTableScroll>
    </div>
  );
};

export default AccountingReceiptDetail;
