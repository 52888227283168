import { useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import Pagination from 'react-js-pagination';
import 'react-loader-spinner/dist/loader/css/react-spinner-loader.css';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import Lupa from '../../assets/img/icons/lupa.svg';
import threeDots from '../../assets/img/icons/threeDots.svg';
import GenericTable from '../../components/Layouts/GenericTableNew';
import ModalNew from '../../components/Layouts/ModalNew';
import paginationStyles from '../../components/Layouts/pagination.module.scss';
import { customSelectNewDark } from '../../components/Layouts/react-select-custom';
import tableStyles from '../../components/Layouts/tableStyle.module.scss';
import CustomPopupExtend from '../../components/Popup/customPopUpExtends';
import { customSwaltAlert } from '../../helpers/customSwaltAlert';
import { loader } from '../../helpers/helpers';
import { useHasPermissionByTagModule } from '../../Hooks';
import { useGetMethod, usePostMethod } from '../../Hooks/useFetch';
import SelectComponent from '../SelectComponent/SelectComponent';
import Styles from './Consultations.module.scss';

const monthNames = [
  'Enero',
  'Febrero',
  'Marzo',
  'Abril',
  'Mayo',
  'Junio',
  'Julio',
  'Agosto',
  'Septiembre',
  'Octubre',
  'Noviembre',
  'Diciembre',
];

const monthOptions = [
  { label: 'Seleccionar...', value: '' },
  { label: 'Enero', value: 1 },
  { label: 'Febrero', value: 2 },
  { label: 'Marzo', value: 3 },
  { label: 'Abril', value: 4 },
  { label: 'Mayo', value: 5 },
  { label: 'Junio', value: 6 },
  { label: 'Julio', value: 7 },
  { label: 'Agosto', value: 8 },
  { label: 'Septiembre', value: 9 },
  { label: 'Octubre', value: 10 },
  { label: 'Noviembre', value: 11 },
  { label: 'Diciembre', value: 12 },
];

function ProvisionSheet() {
  const history = useHistory();
  const counter = useSelector(state => state);
  const myPermission = counter.loginReducer.currentAccount?.profile?.permission?.find(
    x => x.functionality?.prefix === 'plandenom',
  );
  useHasPermissionByTagModule({ module: 'nomina', hasRead: myPermission?.read });
  const token = counter.loginReducer.Authorization;
  const eaccount = counter.loginReducer.currentAccount.id;
  const [trigger, setTrigger] = useState(1);
  const [filters, setFilter] = useState({
    page: 1,
    perpage: 10,
    month: undefined,
    year: '',
    eaccount,
  });

  const {
    load: provisionListLoader,
    results: provisionList,
    trigger: provisionGetMethod,
  } = useGetMethod();

  const { load: generateProvisionLoader, trigger: generateProvisionMethod } = usePostMethod();

  useEffect(() => {
    provisionGetMethod({
      url: '/payroll/provisions',
      objFilters: filters,
      token: token,
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [eaccount, filters.page, trigger]);

  const [showModal, setShowModal] = useState({
    show: false,
  });
  const [generate, setGenerate] = useState({
    eaccount,
    month: '',
    year: '',
    created_by: counter.loginReducer.user_data.id,
  });

  const closeModal = () => {
    setShowModal({ show: false });
    setGenerate(state => ({ ...state, eaccount, month: '', year: '' }));
  };

  const generateProvisions = () => {
    generateProvisionMethod({
      token,
      body: generate,
      method: 'POST',
      url: '/payroll/provisions',
      succesAction: results => {
        customSwaltAlert({
          showCancelButton: false,
          icon: 'success',
          title: 'Creado exitosamente',
          text: results.message,
          confirmButtonText: 'Aceptar',
        }).then(async response => {
          if (response.isConfirmed) {
            setTrigger(trigger + 1);
            closeModal();
          }
        });
      },
      doAfterException: results => {
        customSwaltAlert({
          showCancelButton: false,
          icon: 'warning',
          title: 'Intenta de nuevo',
          text: results?.message,
          confirmButtonText: 'Aceptar',
        });
      },
    });
  };

  const handlePageChange = val => {
    setFilter({
      ...filters,
      page: val,
    });
  };

  const header = [
    <th key={1} className='pl-1 col-2 text-start' style={{ paddingLeft: '10px' }}>
      Descripción
    </th>,
    <th key={2} className='pl-1 col-2 text-start'>
      Año
    </th>,
    <th key={3} className='pl-1 col-2 text-start'>
      Mes
    </th>,

    <th key={8} className='col-1 text-center' style={{ paddingLeft: '10px' }}>
      &nbsp;
    </th>,
  ];

  const body = () => {
    return provisionList?.results?.length > 0 ? (
      provisionList?.results?.map(item => {
        const textMonth = isNaN(parseInt(item.payment_month))
          ? '-'
          : monthNames[parseInt(item.payment_month) - 1];
        return (
          <tr key={'payroll' + item.PS_ID}>
            <td className='text-start col-2' style={{ paddingLeft: '10px' }}>
              {item.details}
            </td>
            <td className='text-start col-2'>
              <b style={{ color: '#005DBF' }}>{item.payment_year}</b>
            </td>
            <td className='text-start col-2'>
              <b style={{ color: '#005DBF' }}>{textMonth}</b>
            </td>

            <td className='col-1'>
              <CustomPopupExtend
                showDetails
                noHover
                triggerSrc={threeDots}
                showDetailsEvent={() => {
                  history.push({
                    pathname: '/nomina/provisionesDeNomina',
                    state: { IdPayroll: item.payrollId },
                  });
                }}
                textDetails='Ver Detalle'
              />
            </td>
          </tr>
        );
      })
    ) : (
      <tr>
        <td colSpan='8' className='text-center'>
          <p style={{ marginTop: '10px' }}>No hay información registrada</p>
        </td>
      </tr>
    );
  };

  const renderPrincipal = (
    <div className={` ml-5 ${tableStyles.container}`} style={{ marginRight: '3rem' }}>
      {(provisionListLoader || generateProvisionLoader) && loader}

      <div className='d-flex'>
        <h1 className={tableStyles.title}>Planillas de provisiones</h1>
      </div>

      {!!myPermission?.read && (
        <>
          <Row className='align-items-end'>
            <Col xs={2}>
              <p className={tableStyles.crudModalLabel}>Mes</p>
              <SelectComponent
                value={monthOptions.filter(x => x.value === filters.month)}
                styles={customSelectNewDark}
                placeholder={'Seleccionar...'}
                key={'state' + trigger}
                onChange={e => {
                  setFilter({
                    ...filters,
                    month: e.value,
                    page: 1,
                  });
                  setTrigger(trigger + 1);
                }}
                options={monthOptions}
              ></SelectComponent>{' '}
            </Col>
            <Col xs={2} className=''>
              <div className=''>
                <p className={tableStyles.crudModalLabel}>Año</p>
                <input
                  className='register-inputs'
                  type='number'
                  placeholder='Escribir...'
                  value={filters.year}
                  onChange={e =>
                    setFilter({
                      ...filters,
                      year: e.target.value,
                      page: 1,
                    })
                  }
                  onKeyDown={e => {
                    if (e.key === 'Enter') {
                      setTrigger(trigger + 1);
                    }
                  }}
                ></input>
              </div>
            </Col>

            <Col xs={8} className='d-flex justify-content-between align-items-end'>
              <div>
                <img
                  className='hoverPointer align-self-end'
                  alt='iconSearch'
                  style={{}}
                  src={Lupa}
                  height='24px'
                  onClick={() => setTrigger(trigger + 1)}
                />
              </div>
              {!!myPermission?.create && (
                <div className='d-flex justify-content-end gap-2'>
                  <span
                    className='darkGray fw-bold align-self-center'
                    style={{ color: '#58595B', fontSize: '13px' }}
                  >
                    Generar planilla{' '}
                  </span>
                  <div
                    className={Styles.btnpreviw}
                    style={{ width: '31px', height: '31px' }}
                    onClick={() => setShowModal({ show: true })}
                  ></div>
                </div>
              )}
            </Col>
          </Row>
          <div></div>
          <GenericTable headers={header} dark={true}>
            {body()}
          </GenericTable>
        </>
      )}

      <ModalNew
        show={showModal.show}
        onHide={() => {
          closeModal();
        }}
        title={'Generar planilla de provisiones'}
        btnYesEvent={() => {
          generateProvisions(generate);
        }}
        btnYesName={'Generar'}
        btnYesDisabled={
          !!myPermission?.create && (!!(generate.year === '') || !!(generate.month === ''))
        }
        btnNoEvent={() => {
          closeModal();
        }}
        btnNoName={'Cancelar'}
        size={'500'}
      >
        <Row className='d-flex mb-2'>
          <Col xs={6} className=' align-self-end'>
            <p className={tableStyles.crudModalLabel}>Mes</p>
            <SelectComponent
              value={monthOptions.filter(x => x.value === generate.month)}
              styles={customSelectNewDark}
              placeholder={'Seleccionar...'}
              key={'state' + trigger}
              onChange={e => {
                setGenerate({
                  ...generate,
                  month: e.value,
                });
              }}
              options={monthOptions}
            ></SelectComponent>{' '}
          </Col>
          <Col xs={6} className=''>
            <div className=''>
              <p className={tableStyles.crudModalLabel}>Año</p>
              <input
                className='register-inputs'
                type='number'
                placeholder='Escribir...'
                value={generate.year}
                onChange={e =>
                  setGenerate({
                    ...generate,
                    year: e.target.value,
                  })
                }
              ></input>
            </div>
          </Col>
        </Row>
      </ModalNew>

      <div className={paginationStyles.wrapper}>
        <p className={` ${paginationStyles.paginationText} darkGray`}>
          pag. {filters.page}
          {' de '}
          {Math.ceil(provisionList.rowTotal / filters.perpage)
            ? Math.ceil(provisionList.rowTotal / filters.perpage)
            : '1'}{' '}
          ({' ' + provisionList.rowTotal}
          {' encontrados'})
        </p>
        <Pagination
          activePage={filters.page}
          itemsCountPerPage={filters.perpage}
          totalItemsCount={provisionList.rowTotal}
          pageRangeDisplayed={5}
          onChange={handlePageChange}
          itemClassPrev={`${paginationStyles.itemClassPrev} `}
          itemClassNext={`${paginationStyles.itemClassNext} `}
          itemClassFirst={`${paginationStyles.itemClassFirst} `}
          itemClassLast={`${paginationStyles.itemClassLast} `}
          itemClass={paginationStyles.itemClass}
        />
      </div>
    </div>
  );

  return renderPrincipal;
}
export default ProvisionSheet;
